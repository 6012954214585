.logos-marquee-wrapper {
  img {
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin: 20px;
    @media #{$sm-layout} {
      margin: 10px;
    }
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 80px;
    content: '';
    height: 100%;
    z-index: 2;
    @media #{$sm-layout} {
      width: 20px;
    }
  }

  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
  }

  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  }
}
